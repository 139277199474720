import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';

import {EMPTY, Observable} from 'rxjs';
import {AntragTicketDTO} from "../../../models/antrag-ticket/AntragTicketDTO";
import {AntragTicketUpdateDTO} from "../../../models/antrag-ticket/AntragTicketUpdateDTO";
import {AntragTicketCreateDTO} from "../../../models/antrag-ticket/AntragTicketCreateDTO";
import {PictureFileDTO} from "../../../models/routing/PictureFileDTO";
import {HttpHeaderService} from "../../utils/http/http-header.service";
import {AntragTicketMinimalDTO} from "../../../models/antrag-ticket/AntragTicketMinimalDTO";
import {catchError} from "rxjs/operators";
import {CustomToastService} from "../../utils/custom-toast.service";

@Injectable({
  providedIn: 'root'
})
export class AntragTicketService {

  configUrl = 'api/antrag-ticket';

  constructor(
    private http: HttpClient,
    private headerService: HttpHeaderService,
    private customToastService: CustomToastService
  ) {}

  getAllAntraegeMinimal(): Observable<Array<AntragTicketMinimalDTO>> {
    return this.http.get<Array<AntragTicketMinimalDTO>>(
      this.configUrl+'/bulk-minimal',
      { headers: this.headerService.getAuthHeaderApplicationJson()}
    );
  }

  getAntrag(id: string): Observable<AntragTicketDTO> {
    return this.http.get<AntragTicketDTO>(
      this.configUrl+'/'+id,
      { headers: this.headerService.getAuthHeaderApplicationJson() }
    );
  }

  updateAntrag(id: number, dto: AntragTicketUpdateDTO, praktikumsvertrag: File, sonderfallNachweis: File, bezugNachweis: File): Observable<any> {
    let formData = new FormData();
    formData.append('dto', new Blob([JSON.stringify(dto)], { type: 'application/json'}));
    if (praktikumsvertrag !== undefined) {
      formData.append('praktikumsvertrag', praktikumsvertrag);
    }
    if (sonderfallNachweis !== undefined) {
      formData.append('sonderfallNachweis', sonderfallNachweis);
    }
    if (bezugNachweis !== undefined) {
      formData.append('bezugNachweis', bezugNachweis);
    }
    return this.http.patch<AntragTicketDTO>(
      this.configUrl+'/'+id,
      formData,
      { headers: this.headerService.getAuthHeader() }
    );
  }

  createAntrag(dto: AntragTicketCreateDTO, praktikumsvertrag: File, sonderfallNachweis: File, bezugNachweis: File): Observable<any> {
    let formData = new FormData();
    formData.append('dto', new Blob([JSON.stringify(dto)], { type: 'application/json'}));
    if (praktikumsvertrag !== undefined) {
      formData.append('praktikumsvertrag', praktikumsvertrag);
    }
    if (sonderfallNachweis !== undefined) {
      formData.append('sonderfallNachweis', sonderfallNachweis);
    }
    if (bezugNachweis !== undefined) {
      formData.append('bezugNachweis', bezugNachweis);
    }
    return this.http.post<AntragTicketDTO>(
      this.configUrl,
      formData,
      { headers: this.headerService.getAuthHeader() }
    );
  }

  deleteAntrag(id: number): Observable<any> {
    return this.http.delete(
      this.configUrl+'/'+id,
      { headers: this.headerService.getAuthHeader(), responseType: 'text' }
    );
  }

  getVorgaenger(schuelerId: number): Observable<any> {
    if (schuelerId !== null) {
      return this.http.get<AntragTicketDTO>(
        this.configUrl+'/vorgaenger?schueler-id='+schuelerId,
        { headers: this.headerService.getAuthHeaderApplicationJson() }
      );
    } else {
      return this.http.get<AntragTicketDTO>(
        this.configUrl+'/vorgaenger',
        { headers: this.headerService.getAuthHeaderApplicationJson() }
      );
    }
  }

  downloadPraktikumsvertrag(antragTicketId: string): Observable<PictureFileDTO> {
    return this.http.get<PictureFileDTO>(
      this.configUrl+'/'+antragTicketId+'/praktikumsvertrag',
      { headers: this.headerService.getAuthHeader() }
    );
  }

  downloadSonderfallNachweis(antragTicketId: string): Observable<PictureFileDTO> {
    return this.http.get<PictureFileDTO>(
      this.configUrl+'/'+antragTicketId+'/sonderfallNachweis',
      { headers: this.headerService.getAuthHeader() }
    );
  }

  downloadBezugNachweis(antragTicketId: string): Observable<PictureFileDTO> {
    return this.http.get<PictureFileDTO>(
      this.configUrl+'/'+antragTicketId+'/bezugNachweis',
      { headers: this.headerService.getAuthHeader() }
    );
  }

  downloadAttachment(antragUuid: string, attachmentUuid: string) {
    let downloadFileName = "";
    this.http.get<Blob>(this.configUrl+'/'+antragUuid+'/download-attachment/'+attachmentUuid, {headers: this.headerService.getAuthHeader(), observe: 'response', responseType: 'blob' as 'json'})
      .pipe(
        catchError(()  => {
          this.customToastService.showError("Der Anhang konnte nicht heruntergeladen werden.");
          return EMPTY;
        })
      )
      .subscribe(response => {
        let contentDisposition = response.headers.get("Content-Disposition")
        if (contentDisposition) {
          const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          const matches = fileNameRegex.exec(contentDisposition);
          if (matches != null && matches[1]) {
            downloadFileName = matches[1].replace(/['"]/g, '');
          }
        }
        let blob = (response as HttpResponse<Blob>).body as Blob;
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.href = url;
        a.download = downloadFileName;
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove();  //afterwards we remove the element again
      });
  }

}
