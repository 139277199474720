import {Component, OnInit} from '@angular/core';
import {BenutzerDTO} from "../../../models/benutzer/BenutzerDTO";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {TranslateService} from "@ngx-translate/core";
import {LoginCredentials, LoginService} from "../../services/auth/login.service";
import {Router} from "@angular/router";
import {TokenResponseDto} from "../../../models/auth/TokenResponseDto";
import {AuthService} from '../../services/auth/auth.service';
import {addSeconds, parseISO} from "date-fns";
import {CustomToastService} from "../../utils/custom-toast.service";
import {faEye, faEyeSlash} from '@fortawesome/free-solid-svg-icons';
import {TextbausteinService} from "../../services/textbaustein/textbaustein.service";
import {ValidationHelperService} from "../../utils/forms/validation-helper.service";
import {catchError} from "rxjs/operators";
import {EMPTY} from "rxjs";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  displayLoginFailed = false;
  loginForm:UntypedFormGroup;

  pwHide = true;
  faEye = faEye;
  faEyeSlash = faEyeSlash;

  user: BenutzerDTO;
  private jwtAnswer: TokenResponseDto;

  constructor(private fb: UntypedFormBuilder,
              public translate: TranslateService,
              private loginService: LoginService,
              private router: Router,
              private authService: AuthService,
              public textbausteinService: TextbausteinService,
              private customToastService: CustomToastService,
              private validationHelper: ValidationHelperService,
              private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.loginForm = this.fb.group({
      username: ['', [Validators.required, Validators.pattern(this.validationHelper.emailPattern)] ],
      password: ['', [Validators.required]]
    });
  }

  login() {
    this.loginForm.markAllAsTouched();
    this.displayLoginFailed = false;

    const loginData: LoginCredentials = {
      username: this.getFormItem('username')?.value.length !==0 ? this.getFormItem('username').value : null,
      password: this.getFormItem('password')?.value.length !==0 ? this.getFormItem('password').value : null,
    }
    if (this.validateLoginForm(loginData))
    {
      this.loginService.login(loginData)
        .pipe(
          catchError(err => {
            this.customToastService.showError(this.translateService.instant( this.getErrorTranslateKey(err) ));
            if(err.error.message.includes('user_locked')) {
              this.redirectToDeadend();
            }
            return EMPTY;
          })
        )
        .subscribe((data: any) =>
          {
            this.jwtAnswer = { ...data }; // '...' flattens / unpacks values
            const timestamp = parseISO(this.jwtAnswer.timestamp)
            this.authService.saveToStorage(
              {
                user: this.jwtAnswer.user_name,
                timestamp: timestamp,
                refreshToken: this.jwtAnswer.refresh_token,
                authToken: this.jwtAnswer.access_token,
                expires: addSeconds(timestamp, this.jwtAnswer.expires_in)
             }
            );
            this.customToastService.showSuccess(
              this.translate.instant('APPLICATION.LOGIN.SUCCESS')
            );
            // force load of pages/home with new sessionStorage
            window.location.href='pages/home';
          }
        );
    }
    else {
      this.customToastService.showError(
        this.translate.instant('APPLICATION.LOGIN.FAILED')
      );
      this.loginFailed()
    }

  }

  getFormItem(s: string) {
    return this.loginForm?.get(s);
  }

  validateLoginForm(loginData: LoginCredentials) {
    return this.validateEmail(loginData.username);
  }

  validateEmail(email: string): boolean {
     return this.validationHelper.isEmailValid(email, true);
  }

  loginFailed(){
    const currentUrlPath = this.router.url
    if (currentUrlPath === '/authentication/login/' )
    {
      this.displayLoginFailed = true;
    }
    else {
      this.router.navigate(['/authentication/login/']);
    }
  }

  getErrorTranslateKey(err: any): string {
    switch(err.status) {
      case 500: {
        return 'COMPOSITE-MESSAGE.UNKNOWN_ERROR';
      }
      case 413: {
        return 'COMPOSITE-MESSAGE.UNKNOWN_ERROR';
      }
      case 412: {
        // TODO: ASM backend refactoring, siehe: https://jira.regioit.intern/browse/SFK2-1426
        //  so dass user mit pending kennwortNeu oder emailNeu nicht als neu registrierte user behandelt werden;
        //  dann muss hier entsprechend ein neuer http.conflict 'reason' eingerichtet und die Fehlermeldung angepasst werden.
        if (err.error.message.includes('user_not_activated'))
          return 'COMPOSITE-MESSAGE.USER_NOT_ACTIVATED_TEMP';
        else if (err.error.message.includes('user_locked'))
          return 'COMPOSITE-MESSAGE.USER_LOCKED';
        else if (err.error.message.includes('invalid_role'))
          return 'COMPOSITE-MESSAGE.INVALID_ROLE';
        else
          return 'COMPOSITE-MESSAGE.UNKNOWN_ERROR';
      }
      case 409: {
        return 'COMPOSITE-MESSAGE.UNKNOWN_ERROR';
      }
      case 407: {
        return 'COMPOSITE-MESSAGE.UNKNOWN_ERROR';
      }
      case 404: {
        return 'COMPOSITE-MESSAGE.NOT_FOUND_OR_NO_CONNECTION';
      }
      case 403: {
        return 'COMPOSITE-MESSAGE.FALSCHER_BENUTZERNAME_PASSWORT';
      }
      case 401: {
        return 'COMPOSITE-MESSAGE.FALSCHER_BENUTZERNAME_PASSWORT';
      }
      default: {
        return 'COMPOSITE-MESSAGE.UNKNOWN_ERROR';
      }
    }

  }

  redirectToDeadend(){
    const navigationExtras = {
      state: {
        title: 'Anmeldung fehlgeschlagen',
        content: '<span>Ihr Konto wurde wegen zu vieler fehlgeschlagener Anmeldeversuche gesperrt. '
          + 'Zum Entsperren nutzen Sie bitte die Funktion: '
          + '<a style="display:inline important!;" href="/authentication/kennwort-vergessen">Passwort zurücksetzen</a>.</span>'
      }
    };
    this.router.navigate(['authentication/info'],navigationExtras);
  }

}
